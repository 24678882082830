<template>
    <el-select
        ref="servicesDropdown"
        v-model="selectedServices"
        :placeholder="$wait.is('services_dropdown_loading') ? $t('common.services') + ' ' + $t('common.loading') : $t('common.services')"
        filterable
        clearable
        :multiple="multiple"
        :class="customClass"
        :disabled="disabled"
        :default-first-option="true"
        size="mini"
        @change="onChange"
    >
        <el-option-group
            v-for="category in servicesWithCategory"
            :key="category"
            :label="category"
        >
            <el-option
                v-for="service in servicesData[category]"
                :key="service.id"
                :label="service.name"
                :value="service.id"
            >
                <div class="flex h-full items-center font-normal text-xs">
                    <span class="flex mr-2 h-4 w-4 rounded" :style="'background:' + service.color" />
                    <span>{{ service.name }}</span>
                </div>
            </el-option>
        </el-option-group>
        <el-option-group :label="$t(`common.without_category`)">
            <el-option
                v-for="service in servicesWithoutCategory"
                :key="service.id"
                :label="service.name"
                :value="service.id"
            >
                <div class="flex h-full items-center font-normal text-xs">
                    <span class="flex mr-2 h-4 w-4 rounded" :style="'background:' + service.color" />
                    <span>{{ service.name }}</span>
                </div>
            </el-option>
        </el-option-group>
    </el-select>
</template>
<script>
export default {
    props: {
        multiple: {
            type:    Boolean,
            default: true,
        },
        customClass: {
            type:    String,
            default: '',
        },
        withoutQuerySearch: {
            type:    Boolean,
            default: false,
        },
        preSelectedService: {
            type:    Number,
            default: null,
        },
        disabled: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            servicesData:     [],
            selectedServices: [],
        };
    },

    computed: {
        query() {
            return this.$route.query;
        },

        queryServices() {
            if (this.multiple) {
                return [].concat(this.query.services || []).map(item => parseInt(item));
            }
            return parseInt(this.query.services) || null;
        },

        servicesWithCategory() {
            return Object.keys(this.servicesData).filter(item => item !== 'without_category');
        },

        servicesWithoutCategory() {
            return this.servicesData.without_category;
        },
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('services_dropdown_loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/services`);
            this.servicesData = data.reduce((group, item) => {
                const groupLocal = group;
                let { category } = item;
                category = category?.name || 'without_category';
                groupLocal[category] = groupLocal[category] ?? [];
                groupLocal[category].push(item);
                return groupLocal;
            }, []);

            await this.$nextTick();
            this.selectedServices = this.preSelectedService || this.queryServices;
            this.$wait.end('services_dropdown_loading');
        },

        async onChange(service) {
            this.$emit('change', this.selectedServices);

            if (this.withoutQuerySearch) return;
            const query = { ...this.query };
            query.services = service;
            await this.$router.replace({ query }).catch(() => {});
        },

        resetSelection() {
            this.selectedServices = [];
            const query = { ...this.query };
            delete query.services;
            this.$router.replace({ query }).catch(() => {});
        },
    },
};
</script>
